<template>
    <GMapAutocomplete
        placeholder="Begin by entering an address."
        @place_changed="setPlace"
        name="autocomplete"
        class="w-11/12 text-xl"
        :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
    >
    </GMapAutocomplete>

</template>
<script>
export default {
    name: 'initsearch',
    data() {
        return {
        }
    },
    methods: {
        setPlace(place) {
            if (place.geometry.location.lat()) {
                this.currentPlace = place;
                latitude.value = this.currentPlace.geometry.location.lat();
                longitude.value = this.currentPlace.geometry.location.lng();
            }
        },
    }
}
</script>

