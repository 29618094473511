//import Vue from "vue";

require('./bootstrap');
require('./mmp');

window.Vue = require('vue').default;

import VueGoogleMaps from '@fawmi/vue-google-maps'
import PropertyResults from './components/PropertyResults.vue'
import VacantLandResults from "./components/VacantLandResults";
import AutoCompleteLocation from './components/AutoCompleteLocation.vue'
import { createApp } from 'vue'

createApp(VacantLandResults)
    .use(VueGoogleMaps, {
        load: {
            key: "AIzaSyBDMDZlU75nbb8CnplUDsL21ZFLaI-oMRA",
            libraries: "places"
        }
    })
    .mount("#vacantland");

createApp(PropertyResults)
    .use(VueGoogleMaps, {
        load: {
            key: "AIzaSyBDMDZlU75nbb8CnplUDsL21ZFLaI-oMRA",
            libraries: "places"
        }
    })
    .mount("#location");

createApp(AutoCompleteLocation)
    .use(VueGoogleMaps, {
        load: {
            key: "AIzaSyBDMDZlU75nbb8CnplUDsL21ZFLaI-oMRA",
            libraries: "places"
        }
    })
    .mount("#initsearch");

createApp(AutoCompleteLocation)
    .use(VueGoogleMaps, {
        load: {
            key: "AIzaSyBDMDZlU75nbb8CnplUDsL21ZFLaI-oMRA",
            libraries: "places"
        }
    })
    .mount("#enterprop");
