<template>
    <div>
        <div class="overflow-y-hidden">
            <div class="navbar navbar-expand-md bg-white z-50 overflow-hidden">
                <form v-on:submit="stopSearch" id="locationSearch">
                    <input type="hidden" id="latitude" name="latitude" />
                    <input type="hidden" id="longitude" name="longitude" />
                    <div class="flex gap-4 pl-4">
                        <div class="flex-1 border-2 w-64">
                            <div class="grid grid-cols-2 p-1">
                                <div class="text-gray-400 focus-within:text-gray-600">
                                    <GMapAutocomplete
                                        @place_changed='setPlace'
                                        onClick="this.select();"
                                        name="autocomplete"
                                        id="autocomplete"
                                        class="bg-transparent block placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent"
                                    ></GMapAutocomplete>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        class="mr-1 grid float-right top-0 items-center"
                                        @click='searchAgain'>
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class=" md:block md:fixed md:w-1/2 md:bottom-0 md:right-0 z-0 ">
                <div class="w-auto overflow-y-scroll " style="height: 85vh; width: 100%;">
                    <div class="rounded-sm bg-white pl-1 pb-1 overflow-hidden shadow divide-y divide-gray-200">
                        <div class="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-3">
                            <div>
                                Search for Vacant Land by selecting an area on the map.
                            </div>
                        </div>
                    </div>
                    <div v-for="property in properties" class="rounded-sm bg-white pl-1 pb-1 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                        <div class="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-16">
                            <div>
                                <div v-for="pimages in property.images_results">
                                    <img :src="pimages.path" alt="" class="absolute inset-0 w-full h-full object-cover" />
                                </div>
                            </div>
                        </div>
                        <div class="sm:rounded-tr-lg relative group bg-white p-4">
                            <div>
                                <h3 class="text-lg font-medium">
                                    <br>
                                    {{ property.city }} {{ property.state }} {{ property.zipcode }}
                                </h3>
                            </div>


                        </div>
                    </div>
                    <div class="lg:col-span-12 pt-5">
                        <div class="lg:grid lg:grid-cols-12 lg:gap-x-4">
                            <div class="lg:col-span-4">
                                <div class="p-2 text-center md:text-left w-full shadow-md hover:shadow-2xl">
                                    <p class="text-xl font-bold">
                                        <button type="button" class="btn btn-outline-dark btn-lg"><a href="">Buy</a></button>
                                    </p>
                                    <p class="text-lg">
                                        <a href="">Let us know where you’d
                                            love to live and we will
                                            match you with a seller in
                                            our network or find a seller
                                            that matches your needs.</a>
                                    </p>
                                </div>
                            </div>
                            <div class="lg:col-span-4">
                                <div class="p-2 text-center md:text-left w-full shadow-md hover:shadow-2xl">
                                    <p class="text-xl font-bold">
                                        <button type="button" class="btn btn-outline-dark btn-lg"><a href="">Join</a></button>
                                    </p>
                                    <p class="text-lg">
                                        <a href="">If you are ready to become
                                            a member simply click the join button and become part of our exclusive network of buyers and sellers.</a>
                                    </p>
                                </div>
                            </div>
                            <div class="sm:flex lg:col-span-4">
                                <div class="p-2 text-center md:text-left w-full shadow-md hover:shadow-2xl">
                                    <p class="text-xl font-bold">
                                        <button type="button" class="btn btn-outline-dark btn-lg"><a href="">Sell</a></button>
                                    </p>
                                    <p class="text-lg">
                                        <a href="">Is your dream home
                                            already a member of our
                                            network? Are you ready to
                                            get you out of your current
                                            home on your own terms?<br>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div class="sm:flex lg:col-span-12">
                                <div class="p-2 text-center md:text-left w-full shadow-md hover:shadow-2xl">
                                    <div class="border-t border-gray-200 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
                                        <div>
                                            <p class="mt-2 text-base text-gray-500">Match My Place, LLC A Licensed Real Estate Broker</p>
                                        </div>

                                    </div>
                                    <div class="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">

                                        <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">&copy; 2022 Match My Place, LLC All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden md:block md:fixed md:w-1/2 md:bottom-0 md:left-0 z-0 overflow-hidden">
                <div style="height: 85vh; width: 100%;">
                    <GMapMap
                        :center="mapCenter"
                        :zoom="10"
                        style="overflow: hidden;"
                        class="h-full"
                        @click="findLocations"
                    >
                        <GMapInfoWindow
                            :options="infoWindowOptions"
                            :position="infoWindowPosition"
                            :opened="infoWindowOpened"
                            @closeclick="handleInfoWindowClose"
                        >
                            <div class="info-window">
                                <h2 v-text="'We found ' + activeLocation.count + ' properties'"></h2>
                                <h2 v-text="'in ' + activeLocation.location"></h2>
                            </div>
                        </GMapInfoWindow>
                        <GMapMarker
                            v-for="(r, index) in location"
                            :key="r.id"
                            :position="getPosition(r)"
                            :clickable="true"
                            :draggable="false"
                            @click="handleMarkerClicked(r)"
                        ></GMapMarker>
                    </GMapMap>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {onMounted} from "vue";

    export default {
        mounted() {
            let place = window.location.pathname.split('/');
            let cleaned_place = decodeURI(place[2]);

            if(cleaned_place)
            {
                this.getGeoCoded({
                    address: cleaned_place
                });
            }
        },
        data() {
            return {
                properties: [],
                location: [],
                infoWindowOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                activeLocation: {},
                infoWindowOpened: false,
                formattedAddresses: [],
                geoCodedNiceAddress: '',
            }
        },
        created() {

        },
        methods: {

            getPosition(r) {
                return {
                    lat: parseFloat(r.latitude),
                    lng: parseFloat(r.longitude)
                }
            },
            handleMarkerClicked(r) {
                this.activeLocation = r;
                this.infoWindowOpened = true;
            },
            handleInfoWindowClose() {
                this.activeLocation = {};
                this.infoWindowOpened = false;
            },
            findLocations(place) {
                this.getGeoCoded({
                    lat: place.latLng.lat(),
                    lng: place.latLng.lng(),
                });
            },
            getGeoCoded(m) {
                axios.post('/api/search-land',
                    {
                        address: m.address,
                        latitude: m.lat,
                        longitude: m.lng,
                        _token: $('meta[name="csrf-token"]').attr('content')
                    }
                )
                .then(
                    (response) => {
                        this.location = response.data[1]
                        this.properties = response.data[0]
                    }
                );
            },
            setPlace(place) {
                if(place.geometry.location.lat())
                {
                    this.currentPlace = place;
                    latitude.value = this.currentPlace.geometry.location.lat();
                    longitude.value = this.currentPlace.geometry.location.lng();

                    this.location = [];
                    this.location.push({
                        location: place,
                        latitude: this.currentPlace.geometry.location.lat(),
                        longitude: this.currentPlace.geometry.location.lng()
                    });
                    this.searchAgain();
                } else {
                    alert('Please select a place.')
                }

            },
            searchAgain(){
                if(this.currentPlace === undefined) {
                    alert('Please select a location.')
                } else {
                    axios.post('/api/search-land',
                        {
                            autocomplete: autocomplete.value,
                            latitude: latitude.value,
                            longitude: longitude.value,
                            _token: $('meta[name="csrf-token"]').attr('content')
                        }
                    )
                    .then(
                        (response) => {
                            this.location = response.data[1]
                            this.properties = response.data[0]
                            window.history.pushState({}, '', autocomplete.value);
                        }
                    );

                }
            },
            stopSearch(e){
                e.preventDefault()
            },
        },
        computed: {
            mapCenter() {
                if (!this.location.length) {
                    return {
                        lat: 28.541666,
                        lng: -81.375686
                    }
                }

                return {
                    lat: parseFloat(this.location[0].latitude),
                    lng: parseFloat(this.location[0].longitude)
                }
            },
            infoWindowPosition() {
                return {
                    lat: parseFloat(this.activeLocation.latitude),
                    lng: parseFloat(this.activeLocation.longitude)
                };
            },
        },
    }
</script>
