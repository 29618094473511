<template>
    <div>
        <div class="overflow-y-hidden">
            <div class="bg-white overflow-hidden z-1000">
                <form v-on:submit="stopSearch">
                    <input type="hidden" id="latitude" name="latitude" />
                    <input type="hidden" id="longitude" name="longitude" />
                    <div class="flex">
                        <div class="relative flex-none w-25 border-2 ">
                            <div class="text-gray-400 focus-within:text-gray-600">
                                <GMapAutocomplete
                                    @place_changed='setPlace'
                                    onClick="this.select();"
                                    name="autocomplete"
                                    id="autocomplete"
                                    class="w-5/6 text-lg bg-transparent block placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent"
                                ></GMapAutocomplete>
                            </div>
                            <div >
                                <button
                                    type="submit"
                                    class="absolute top-0 right-0 align-top"
                                    @click='searchAgain'>
                                    <svg class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div class="flex-1 w-25">
                            <button aria-expanded="false" data-bs-toggle="dropdown" type="button" class="dropdown-toggle btn btn-outline-info" id="price-menu" aria-haspopup="true">
                                <span class="sr-only">Open user menu</span>
                                Price Range
                            </button>
                            <ul class="dropdown-menu p-3">
                                <li><input type="checkbox" id="price_any" name="price_any" value="price_any" @click='searchAgain'> Any</li>
                                <li><input type="checkbox" id="price_100" name="price_100" value="price_100" @click='searchAgain'> $100,000 +</li>
                                <li><input type="checkbox" id="price_200" name="price_200" value="price_200" @click='searchAgain'> $200,000 +</li>
                                <li><input type="checkbox" id="price_300" name="price_300" value="price_300" @click='searchAgain'> $300,000 +</li>
                                <li><input type="checkbox" id="price_400" name="price_400" value="price_400" @click='searchAgain'> $400,000 +</li>
                                <li><input type="checkbox" id="price_500" name="price_500" value="price_500" @click='searchAgain'> $500,000 +</li>
                            </ul>

                            <button aria-expanded="false" data-bs-toggle="dropdown" type="button" class="dropdown-toggle btn btn-outline-info" id="bedroom-menu" aria-haspopup="true">
                                <span class="sr-only">Open user menu</span>
                                Beds/Rooms
                            </button>
                            <ul class="dropdown-menu p-3">
                                <li><input type="checkbox" id="bedroom_any"  name="bedroom_any" value="bedroom_any" @click='searchAgain'> Any</li>
                                <li><input type="checkbox" id="bedroom_1" name="bedroom_1" value="bedroom_1" @click='searchAgain'> 1 Bedrooms</li>
                                <li><input type="checkbox" id="bedroom_2" name="bedroom_2" value="bedroom_2" @click='searchAgain'> 2 Bedrooms</li>
                                <li><input type="checkbox" id="bedroom_3" name="bedroom_3" value="bedroom_3" @click='searchAgain'> 3 Bedrooms</li>
                                <li><input type="checkbox" id="bedroom_4" name="bedroom_4" value="bedroom_4" @click='searchAgain'> 4 Bedrooms</li>
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
            <div class=" md:block md:fixed md:w-1/2 md:bottom-0 md:right-0">
                <div class="w-auto overflow-y-scroll " style="height: 85vh; width: 100%;">

                    <div v-for="property in properties" class="rounded-sm bg-white pl-1 pb-1 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                        <div class="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-32">
                            <div>
                                <div v-for="pimages in property.images_results">
                                    <img :src="pimages.path" alt="" class="absolute inset-0 w-full h-full object-cover" />
                                </div>
                            </div>
                        </div>
                        <div class="sm:rounded-tr-lg relative group bg-white p-4">
                            <div>
                                <h3 class="text-lg font-medium">
                                    <br>
                                    {{ property.city }} {{ property.state }} {{ property.zipcode }}
                                </h3>
                            </div>
                            <div class="mt-4">
                                <button type="button" :data-bs-id="property.id" class="btn btn-outline-dark btn-lg" data-bs-toggle="modal" data-bs-target="#MatchPropertyDetails">
                                    Details
                                </button>
                            </div>
                            <span v-if="property.property_details_results.list_price" class="absolute bottom-2 lg:left-2 left-40 text-lg" aria-hidden="true">
                                List Price ${{ property.property_details_results.list_price }}
                            </span>
                            <span class="absolute bottom-2 right-2 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                                <div>
                                    <form>
                                        <div class="form-check form-heart">
                                            <input class="form-check-input" type="checkbox" v-model="property.favorited" :value="property.id" v-on:click="updateFavorite($event)">
                                        </div>
                                    </form>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="lg:col-span-12 pt-5">
                        <div class="lg:grid lg:grid-cols-12 lg:gap-x-4">
                            <div class="lg:col-span-4">
                                <div class="p-2 text-center md:text-left w-full shadow-md hover:shadow-2xl">
                                    <p class="text-xl font-bold">
                                        <button type="button" class="btn btn-outline-dark btn-lg"><a href="">Buy</a></button>
                                    </p>
                                    <p class="text-lg">
                                        <a href="">Let us know where you’d
                                            love to live and we will
                                            match you with a seller in
                                            our network or find a seller
                                            that matches your needs.</a>
                                    </p>
                                </div>
                            </div>
                            <div class="lg:col-span-4">
                                <div class="p-2 text-center md:text-left w-full shadow-md hover:shadow-2xl">
                                    <p class="text-xl font-bold">
                                        <button type="button" class="btn btn-outline-dark btn-lg"><a href="">Join</a></button>
                                    </p>
                                    <p class="text-lg">
                                        <a href="">If you are ready to become
                                            a member simply click the join button and become part of our exclusive network of buyers and sellers.</a>
                                    </p>
                                </div>
                            </div>
                            <div class="sm:flex lg:col-span-4">
                                <div class="p-2 text-center md:text-left w-full shadow-md hover:shadow-2xl">
                                    <p class="text-xl font-bold">
                                        <button type="button" class="btn btn-outline-dark btn-lg"><a href="">Sell</a></button>
                                    </p>
                                    <p class="text-lg">
                                        <a href="">Is your dream home
                                            already a member of our
                                            network? Are you ready to
                                            get you out of your current
                                            home on your own terms?<br>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div class="sm:flex lg:col-span-12">
                                <div class="p-2 text-center md:text-left w-full shadow-md hover:shadow-2xl">
                                    <div class="border-t border-gray-200 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
                                        <div>
                                            <p class="mt-2 text-base text-gray-500">Match My Place, LLC A Licensed Real Estate Broker</p>
                                        </div>

                                    </div>
                                    <div class="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">

                                        <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">&copy; 2022 Match My Place, LLC All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden md:block md:fixed md:w-1/2 md:bottom-0 md:left-0 overflow-hidden z-0">
                <div style="height: 85vh; width: 100%;">
                    <GMapMap
                        :center="mapCenter"
                        :zoom="10"
                        style="overflow: hidden;"
                        class="h-full"
                        @click="findLocations"
                    >
                        <GMapInfoWindow
                            :options="infoWindowOptions"
                            :position="infoWindowPosition"
                            :opened="infoWindowOpened"
                            @closeclick="handleInfoWindowClose"
                        >
                            <div class="info-window">
                                <h2 v-text="'We found ' + activeLocation.count + ' properties'"></h2>
                                <h2 v-text="'in ' + activeLocation.location"></h2>
                            </div>
                        </GMapInfoWindow>
                        <GMapMarker
                            v-for="(r, index) in location"
                            :key="r.id"
                            :position="getPosition(r)"
                            :clickable="true"
                            :draggable="false"
                            @click="handleMarkerClicked(r)"
                        ></GMapMarker>
                    </GMapMap>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        mounted() {
            let place = window.location.pathname.split('/');
            let cleaned_place = decodeURI(place[2]);

            if(cleaned_place)
            {
                this.getGeoCoded({
                    address: cleaned_place
                });
            }
        },
        data() {
            return {
                properties: [],
                location: [],
                infoWindowOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                activeLocation: {},
                infoWindowOpened: false,
                formattedAddresses: [],
                geoCodedNiceAddress: '',
            }
        },
        created() {

        },
        methods: {

            getPosition(r) {
                return {
                    lat: parseFloat(r.latitude),
                    lng: parseFloat(r.longitude)
                }
            },
            handleMarkerClicked(r) {
                this.activeLocation = r;
                this.infoWindowOpened = true;
            },
            handleInfoWindowClose() {
                this.activeLocation = {};
                this.infoWindowOpened = false;
            },
            findLocations(place) {
                this.currentPlace = place;

                latitude.value = place.latLng.lat();
                longitude.value = place.latLng.lng();

                    this.getGeoCoded({
                    lat: place.latLng.lat(),
                    lng: place.latLng.lng(),
                });
            },
            getGeoCoded(m) {
                axios.post('/api/search',
                    {
                        address: m.address,
                        latitude: m.lat,
                        longitude: m.lng,
                        bedroom_any: bedroom_any.checked,
                        bedroom_1: bedroom_1.checked,
                        bedroom_2: bedroom_2.checked,
                        bedroom_3: bedroom_3.checked,
                        bedroom_4: bedroom_4.checked,
                        price_any: price_any.checked,
                        price_100: price_100.checked,
                        price_200: price_200.checked,
                        price_300: price_300.checked,
                        price_400: price_400.checked,
                        price_500: price_500.checked,
                        _token: $('meta[name="csrf-token"]').attr('content')
                    }
                )
                .then(
                    (response) => {
                        this.location = response.data[1]
                        this.properties = response.data[0]

                    }
                );
            },
            setPlace(place) {
                if(place.geometry.location.lat())
                {
                    this.currentPlace = place;
                    alert(this.currentPlace);

                    latitude.value = this.currentPlace.geometry.location.lat();
                    longitude.value = this.currentPlace.geometry.location.lng();

                    this.location = [];
                    this.location.push({
                        location: place,
                        latitude: this.currentPlace.geometry.location.lat(),
                        longitude: this.currentPlace.geometry.location.lng()
                    });
                    this.searchAgain();
                } else {
                    alert('Please select a place.')
                }

            },
            searchAgain(){
                if(this.currentPlace === undefined) {
                    alert('Please select a location.')
                } else {
                    axios.post('/api/search',
                        {
                            address: autocomplete.value,
                            latitude: latitude.value,
                            longitude: longitude.value,
                            bedroom_any: bedroom_any.checked,
                            bedroom_1: bedroom_1.checked,
                            bedroom_2: bedroom_2.checked,
                            bedroom_3: bedroom_3.checked,
                            bedroom_4: bedroom_4.checked,
                            price_any: price_any.checked,
                            price_100: price_100.checked,
                            price_200: price_200.checked,
                            price_300: price_300.checked,
                            price_400: price_400.checked,
                            price_500: price_500.checked,
                            _token: $('meta[name="csrf-token"]').attr('content')
                        }
                    )
                    .then(
                        (response) => {
                            this.location = response.data[1]
                            this.properties = response.data[0]
                            window.history.pushState({}, '', '/search/' + autocomplete.value);
                        }
                    );

                }
            },
            updateFavorite: function (e) {
                if (true) {
                    axios({
                        method: 'post',
                        url: '/my/favorite',
                        data: {
                            property_id: e.target.value,
                            favorite: e.target.checked,
                            _token: $('meta[name="csrf-token"]').attr('content')
                        }
                    })
                        .catch(function (error) {
                            if (error.response) {
                                // Request made and server responded
                            } else if (error.request) {
                                // The request was made but no response was received
                            } else {
                            }
                        });
                } else {
                    e.target.checked = !e.target.checked;
                    $('#MatchPropertyDetails').modal('show');
                }
            },
            stopSearch(e){
                e.preventDefault()
            },
        },
        computed: {
            mapCenter() {
                if (!this.location.length) {
                    return {
                        lat: 28.541666,
                        lng: -81.375686
                    }
                }

                return {
                    lat: parseFloat(this.location[0].latitude),
                    lng: parseFloat(this.location[0].longitude)
                }
            },
            infoWindowPosition() {
                return {
                    lat: parseFloat(this.activeLocation.latitude),
                    lng: parseFloat(this.activeLocation.longitude)
                };
            },
        },
    }
</script>
