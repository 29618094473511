
if(document.getElementById('MatchPropertyDetails')) {
    var detailModal = document.getElementById('MatchPropertyDetails')
    detailModal.addEventListener('show.bs.modal', function (event) {
        // Button that triggered the modal
        var button = event.relatedTarget
        // Extract info from data-bs-* attributes
        var prop_id = button.getAttribute('data-bs-id')
        // If necessary, you could initiate an AJAX request here
        // and then do the updating in a callback.
        //
        $('#MatchPropertyDetails').find('.modal-body').html('Loading...');

        $.get("/match/details/" + prop_id, function (data) {
            $('#MatchPropertyDetails').find('.modal-body').html(data);
        })


    })
}

setInterval( () => {
    $.get("/api/alerts", function (data) {
        $('#liveToast').html(data);
        data && $('.toast').toast('show');
    })
}, 15000);//wait 15 seconds
